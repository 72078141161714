// SignIn.js

import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Card, Fade, Checkbox, Link, Box } from '@mui/material';
import { auth, provider } from "../firebase-auth";
import { signInWithPopup } from "firebase/auth";
import { API_URL } from './constants';

const SignIn = ({ setUser }) => {
  const [showContent, setShowContent] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
        manageUser(result.user);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const manageUser = async (user) => {
    const formData = new FormData();
    formData.append('user_id', user.uid);
    formData.append('display_name', user.displayName);
    formData.append('email', user.email);
    formData.append('phone_number', user.phoneNumber);
    formData.append('photo_url', user.photoURL);
    formData.append('account_creation_time', user.metadata.creationTime);
    formData.append('last_login_time', user.metadata.lastSignInTime);

    try {
      const response = await fetch(`${API_URL}/api/manage-user`, {
        method: 'POST',
        body: formData
      });
      console.log(response);
    } catch (error) {
      console.log('Error Adding/Updating user.');
    }
  };

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <Grid container sx={{ height: '100vh' }}>
      {/* Left column with image */}
      <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          src="../Classification.gif"
          alt="Description"
          style={{ width: '100%', maxWidth: '100%', height: '100%' }}
        />
      </Grid>

      {/* Right column with text and button */}
      <Grid item xs={12} md={5}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <Fade in={showContent} timeout={1000}>
            <Typography variant="h4" gutterBottom>
              Welcome to DataCreator AI!
            </Typography>
          </Fade>

          <Fade in={showContent} timeout={1500}>
            <Typography
              variant="body1"
              paragraph
              align="center"
              sx={{
                maxWidth: '80%',
                mb: 4,
                '& blockquote': {
                  borderLeft: '4px solid #1976d2',
                  paddingLeft: '20px',
                  marginLeft: '20px',
                  fontStyle: 'italic'
                }
              }}
            >
              <p>
                Generate data for several tasks like Classification, Question and Answering,
                Instruction Tuning, Named Entity Recognition without any tech background.
                Don't wait to be a part of the AI Revolution!
              </p>
            </Typography>
          </Fade>

          {/* Checkbox for agreeing to Terms and Conditions */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              inputProps={{ 'aria-label': 'Agree to Terms and Conditions' }}
            />
            <Typography variant="body2">
              I agree to the{' '}
              <Link
                href="/terms-and-privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </Link>
            </Typography>
          </Box>

          <Fade in={showContent} timeout={2000}>
            <Button
              onClick={signInWithGoogle}
              sx={{
                backgroundColor: '#f5f5f5',
                color: '#000000',
                textTransform: 'none',
                padding: '10px 20px',
                border: '1px solid #dcdcdc',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
              disabled={!isChecked}
            >
              <img width="30px" src="google_logo.png" alt="Google logo" style={{ marginRight: '20px' }} />
              SignIn with Google
            </Button>
          </Fade>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SignIn;
