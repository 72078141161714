import React from 'react';
import { Grid, Typography, Button, Card, AppBar, Toolbar, Link, Container, Box, CardContent } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import { styled } from '@mui/material/styles';
import TopWaveSVG from './TopWave'; 
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
// import demo_video from '../../public/Demo_Data_Creator.mp4';
import CTASection from './CTASubsection';
import Carousel from './Carousel';
import PricingSection from './PricingSection'; 

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  transition: 'background-color 0.3s ease-in-out',
}));

const StyledSection = styled(Box)(({ theme }) => ({
  minHeight: '80vh',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0), // Adjusted padding to reduce top margin
  background: "url('Website.jpg') no-repeat center center/cover" // 'linear-gradient(to bottom, #e0e0e0, #b0c4d8)', // Nice background
}));

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(6, 0),
  marginTop: 'auto',
  background: "url('Website.jpg') no-repeat center center/cover"
}));

const SocialIcon = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  margin: theme.spacing(0, 1),
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const CTASubSection = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(4, 0),
  background: "url('Website.jpg') no-repeat center center/cover" // Further reduced padding for CTA section
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  }
}));

const LandingPage = () => {
  const ctaSection = {
    name: "cta",
    title: "Create Your",
    subtitle: "Own Dataset.",
    description: "Create custom synthetic datasets effortlessly with our powerful data generation tool. Incorporate custom knowledge and mimic existing data. Perfect for developers, data scientists, and AI businesses looking to get started with AI but do not have enough data yet.",
    image: "MainImage.jpg",
    cta: true
  };

  const sections = [
    {
      name: "product",
      title: "",
      description: "",
      images: ["TeachersDay.png", "Chathurthi_Hindi.png", "Chathurthi_Telugu.png"]
    },
    {
      name: "features",
      title: "Key Features",
      description: "Explore our powerful features designed to streamline your data generation process:",
      image: "path/to/your/features-image.jpg",
      featureCards: [
        {
          title: "Multiple Usecase Support",
          description: "Create Synthetic data for any usecase such as Text Classification, Named Entity Recognition, Instruction Fine-Tuning across various domains."
        },
        {
          title: "Real-time Data Previews",
          description: "Instantly visualize your generated data to ensure it meets your expectations before exporting."
        },
        // {
        //   title: "Flexible Export Options",
        //   description: "Export your synthetic data in various formats, including CSV and JSON to seamlessly integrate with your existing workflows."
        // },
        {
          title: "Enchance Existing Datasets",
          description: "Upload your own custom knowledge or data sources and generate data that reflects the style and knowledge of the existing data."
        },
        {
          title : "Multilingual Support",
          description: "You can generate synthetic data for NLP applications in multiple non-English languages such as Hindi, Telugu and German. We are adding more languages everyday."
        }
      ]
    },
    // {
    //   name: "pricing",
    //   title: "",
    //   description: "",
    //   component: <PricingSection />
    // }
    // {
    //   name: "about",
    //   title: "About Us",
    //   description: "We are committed to democratizing data. Our mission is to make data accessible and useful for everyone, fostering innovation and growth across industries.",
    //   image: "path/to/your/about-image.jpg"
    // }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <StyledAppBar position="fixed">
        <Container>
          <Toolbar disableGutters>
            <RouterLink to="/" style={{ textDecoration: 'none', flexGrow: 1 }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'blue' }}>
                DataCreator AI
              </Typography>
            </RouterLink>
            {/* <RouterLink 
          to="/blog" 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Blog
        </RouterLink> */}
            {sections.map((section, index) => (section.title != '' &&
              <ScrollLink key={index} to={section.name} smooth={true} duration={500} offset={-70}>
                <Button
                  variant="contained"
                  sx={{
                    mx: 1,
                    textTransform: 'none',
                    borderRadius: '20px', // More rounded borders
                    padding: '8px 16px',
                    backgroundColor: 'white', // Slightly transparent primary blue
                    color: 'blue',
                    '&:hover': {
                      backgroundColor: 'white',
                    }
                  }}
                >
                  {section.title}
                </Button>
              </ScrollLink>
            ))}
            {/* <Button
                  variant="contained"
                  sx={{
                    mx: 1,
                    textTransform: 'none',
                    borderRadius: '20px', // More rounded borders
                    padding: '8px 16px',
                    backgroundColor: 'white', // Slightly transparent primary blue
                    color: 'blue',
                    '&:hover': {
                      backgroundColor: 'white',
                    }
                  }}
                  // onclick = {handleClick}
                >
                  Signup
             </Button> */}
          </Toolbar>
        </Container>
      </StyledAppBar>

      <CTASection />

      {/* Wave Divider */}
      {/* <TopWaveSVG /> */}

      {/* Other Sections */}
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          <Element name={section.name}>
            <StyledSection>
              <Container>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom color="blue" textAlign="center">
                      {section.title}
                    </Typography>
                    <Typography variant="body1" paragraph textAlign="center">
                      {section.description}
                    </Typography>
                  </Grid>
                  {/* {section.component ? (
                    <Grid item xs={12}>
                      {section.component}
                    </Grid>
                  ): */}
                  {section.featureCards ? (
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        {section.featureCards.map((feature, featureIndex) => (
                          <Grid item xs={12} sm={6} md={3} key={featureIndex}>
                            <FeatureCard sx = {{"borderRadius" : "20px"}}>
                              <CardContent>
                                <Typography gutterBottom sx = {{'color': 'blue'}}>
                                  {feature.title}
                                </Typography>
                                <Typography variant="body2">
                                  {feature.description}
                                </Typography>
                              </CardContent>
                            </FeatureCard>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    section.video ? (
                      <Grid item xs={12}>
                        <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                        >
                          <video autoPlay controls loop id="video" width="550" height="300">
                            <source src={`${process.env.PUBLIC_URL}/Demo_Data_Creator.mp4`} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </Box>
                      </Grid>
                    ) : 
                        <Grid item xs={12}>
                          <Carousel images={section.images} />
                        </Grid> 
                        // <Grid item xs = {12}>
                        //   <PricingSection />
                        // </Grid>
                  )}
                </Grid>
              </Container>
            </StyledSection>
          </Element>
        </React.Fragment>
      ))}

      <Footer>
        <Container maxWidth="lg" background-color = "black">
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} textAlign="center">
              {/* <SocialIcon href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FacebookIcon /> "url('Website.jpg') no-repeat center center/cover"
              </SocialIcon> */}
              <Typography sx = {{'color': 'blue'}}>
                Follow us:
              </Typography>
              <Box sx = {{'padding': "12px"}}>
                <SocialIcon href="https://www.linkedin.com/company/syntheta/" target="_blank" rel="noopener noreferrer">
                  <LinkedInIcon />
                </SocialIcon>
              <SocialIcon href="https://www.instagram.com/priyankamadiraju/" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </SocialIcon>
              <SocialIcon href="https://x.com/priyankanew27" target="_blank" rel="noopener noreferrer">
                <TwitterIcon />
              </SocialIcon>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary" align="center">
                © {new Date().getFullYear()} DataCreator AI. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Footer>
    </Box>
  );
};

export default LandingPage;
