import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const CTASection = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/signin");
  };

  const handleEmailClick = () => {
    window.location.href = "https://discord.gg/CEjACg45";
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.5, ease: "easeOut" } }
  };

  const slideUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  return (
    <Box
      sx={{
        background: "url('Website.jpg') no-repeat center center/cover",
        padding: '4rem 2rem',
        textAlign: 'center',
        display: 'flex',
        minHeight: '50vh',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            py: 8, // Vertical padding
          }}
        >
      <motion.div initial="hidden" animate="visible" variants={fadeIn}>
        <Typography
          variant="subtitle1"
          sx={{
            color: 'blue',
            marginBottom: '1rem',
            fontSize: '1.2rem',
          }}
        >
          Infinite Data, Limitless Possibilities!
        </Typography>
      </motion.div>
      <motion.div initial="hidden" animate="visible" variants={slideUp}>
        <Typography
          variant="h1"
          sx={{
            color: 'blue',
            fontWeight: 'bold',
            fontSize: '3rem',
            marginBottom: '1.5rem',
          }}
        >
          Never Run Out of Data.
        </Typography>
      </motion.div>
      <motion.div initial="hidden" animate="visible" variants={fadeIn}>
        <Typography
          variant="body1"
          sx={{
            color: 'black',
            maxWidth: '600px',
            marginBottom: '2rem',
            fontSize: '1.1rem',
          }}
        >
          Create custom synthetic datasets effortlessly with our powerful data generation tool. 
          Perfect for developers, data scientists, and AI businesses.
        </Typography>
      </motion.div>
      <motion.div initial="hidden" animate="visible" variants={slideUp}>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Button
          variant="contained"
          onClick={handleClick}
          sx={{
            backgroundColor: 'blue',
            color: 'white',
            padding: '0.75rem 1.5rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#4A0FB0',
            }
          }}
        >
          Get Started for Free
        </Button>
        
        <Button
          variant="outlined"
          onClick={handleEmailClick}
          sx={{
            borderColor: 'blue',
            color: 'blue',
            padding: '0.75rem 1.5rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#F0E6FF',
            },
          }}
        >
          Join Us on Discord
        </Button>
      </Box>
      </motion.div>
    </Box>
    </Container>
    </Box>
  );
};

export default CTASection;