// src/Header.js
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';

const Header = ({ title, onLogout }) => {
  return (
    <AppBar position="static" sx={{
        background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, rgba(240, 240, 240, 0.7) 100%)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        padding: '0 20px'
      }}>
      <Toolbar>
        <RouterLink to="/" style={{ textDecoration: 'none', flexGrow: 1 }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'blue' }}>
                DataCreator AI
              </Typography>
        </RouterLink>
        {/* <Button
                  variant="contained"
                  sx={{
                    mx: 1,
                    textTransform: 'none',
                    borderRadius: '20px', // More rounded borders
                    padding: '8px 16px',
                    backgroundColor: 'white', // Slightly transparent primary blue
                    color: 'blue',
                    '&:hover': {
                      backgroundColor: 'white',
                    }
                  }}
                >
                  REVIEW DATA
          </Button> */}
        <Button
                  variant="contained"
                  onClick={onLogout}
                  sx={{
                    mx: 1,
                    textTransform: 'none',
                    borderRadius: '20px', // More rounded borders
                    padding: '8px 16px',
                    backgroundColor: 'white', // Slightly transparent primary blue
                    color: 'blue',
                    '&:hover': {
                      backgroundColor: 'white',
                    }
                  }}
                >
                  LOGOUT
         </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;