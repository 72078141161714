// src/App.js
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebase-auth';
import SignIn from './components/SigninPage';
import Home from './components/Home';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import ReactGA from 'react-ga4';
import TermsAndPrivacy from './components/TermsAndPrivacy';
import BlogPost from './components/Blog';
import Blog from './components/Blogs'

function App() {
  
  ReactGA.initialize('G-CCYJ25YQQ8');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        console.log(currentUser);
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error during logout: ", error);
    }
  };

  return (
    <div>
      <BrowserRouter>
        {user && <Header title="DataCreator AI" onLogout={handleLogout} />}
        <Routes>
          <Route path="/" element={user ? <Home user={user} /> : <LandingPage />} />
          <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />
          <Route path="/signin" element={user ? <Navigate to="/" /> : <SignIn setUser={setUser} />} />
          {/* <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} /> */}
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
