import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { TextField, Button, Box, Grid, CircularProgress, MenuItem, Select, FormControl, 
  InputLabel, Card, CardContent, Container, IconButton, Typography, 
  Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import GridComponent from './UpdatableGrid';
import { toast, ToastContainer } from 'react-toastify';
import { AttachFile, Close } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import PopupFormComponent from './EvaluationForm';
import { API_URL } from './constants';
import TermsDialog from './TermsandCondition';

const Home = ({user}) => {
  const [prompt, setPrompt] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [showGrid, setShowGrid] = useState(false);
  const [number, setNumber] = useState(1000);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);

  const [isDisabled, setIsDisabled] = useState(true);
  const [receivedCount, setReceivedCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 15 * 1024 * 1024) {
        toast.error('File size exceeds 15 MB limit. Please upload a smaller file.');
        return;
      }
      setFileName(file.name);
      setUploadedFile(file);
      setLoading(false);
      toast.info("This file will not be stored beyond the current data request. Please contact the support team for more details.")
    }
   };

   const handleRemoveFile = () => {
    setFileName('');
    setUploadedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validateInputs = () => {
    if (prompt.trim().length === 0 || prompt.split(' ').length < 3) {
      toast.error('Please enter a description with at least 3 words.');
      return false;
    }

    if (selectedOption.trim().length === 0) {
      toast.error('Please select a task.');
      return false;
    }

    if (!isNaN(number) && Number(number) > 1000) {
      toast.error('We support only 1000 data points at the moment. Please contact the sales team for more details.');
      return false;
    }

    if (isNaN(number) || Number(number) < 0) {
      toast.error('Invalid Number of Records! Please enter a valid number.');
      return false;
    }

    return true;
  };

  const convertJsonStringToArray = (jsonString) => {
    // Use a regular expression to match JSON-like objects, including those with newlines
    const jsonRegex = /{(?:[^{}]|(?:\{[^{}]*\}))*}/g;
    const jsonObjects = jsonString.match(jsonRegex);

    if(jsonObjects === null) return [];
  
    // Parse each JSON object and create an array
    return jsonObjects.map(json => {
      try {
        // Replace escaped newlines with actual newlines
        const cleanedJson = json.replace(/\\n/g, '');
        return JSON.parse(cleanedJson);
      } catch (error) {
        console.error('Error parsing JSON object:', json);
        console.error('Error details:', error);
        return null; // Return null for objects that couldn't be parsed
      }
    }).filter(obj => obj !== null); // Remove any null entries
  };
   
  const fetchRowData = async () => {
    if (!validateInputs()) {
      return;
    }

    setLoading(true);
    setRowData([]);
    setIsDisabled(true);
    setReceivedCount(0);

    try {
      const formData = new FormData();
      formData.append('prompt', prompt);
      formData.append('usecase', selectedOption);
      formData.append('number', number);
      formData.append('language', selectedLanguage);
      formData.append('user_id', user.uid);
      formData.append('include_search_results', isChecked);

      if (uploadedFile) {
        formData.append('file', uploadedFile);
      }

      if (selectedOption === 'classification') {
        setColDefs([
          {
            field: "Text", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 4
          },
          { field: "Label", editable: true, flex: 1}
        ]);
      }
      else if (selectedOption === 'generation') {
        setColDefs([{ field: "Text", editable: true, flex: 5 }]);
      }
      else if (selectedOption === 'qanda') {
        setColDefs([
          {
            field: "Question", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Answer", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 3
          },
        ]);
      }
      else if(selectedOption === 'insttuning'){
        setColDefs([
          {
            field: "Instruction", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Output", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 3
          }]
        )
      }
    
      const response = await fetch(`${API_URL}/api/generate-data`, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoading(true);
      // const results = await response.json();
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setIsDisabled(false);
          break
        }
        const chunk = decoder.decode(value);
        const items = JSON.parse(JSON.stringify(chunk));
        console.log("Items")
        console.log(items);

          const items_converted = convertJsonStringToArray(items);
          if("message" in items_converted[0]){
            toast.error(items_converted[0].message);
            break
          }
          setShowGrid(true);
          setLoading(false);
          setRowData(prevData => prevData.length >= number ? prevData : [...prevData, ...items_converted]);
          setReceivedCount(prevCount => prevCount + items_converted.length >= number ? number : prevCount + items_converted.length);
      }
    } catch (error) {
      console.error('Error fetching row data:', error);
      toast.error("An Error occured! Please try again or contact the support team.")
    }
    finally {
      setLoading(false);
      setShowGrid(true);
      setIsDisabled(false);
    }
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setRowData([]);
    setColDefs([]);
    setShowGrid(false);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleSubmit = () => {
    // setLoading(true);
    // toast.warning("Warning: Generation of explicit, NSFW, vulgar, or offensive content is prohibited. Violation will result in a disabled account. Users are responsible for appropriate use.");
    fetchRowData();
  };

  const prepareCSVContent = () => {
    const escapeCSV = (cell) => {
      if (cell == null) return '';
      cell = cell.toString();
      if (cell.includes('"') || cell.includes(',') || cell.includes('\n')) {
        return `"${cell.replace(/"/g, '""')}"`;
      }
      return cell;
    };

    const csvHeader = colDefs.map(col => escapeCSV(col.field)).join(',') + '\n';
    const csvData = rowData.map(row => 
      colDefs.map(col => escapeCSV(row[col.field])).join(',')
    ).join('\n');

    const BOM = '\uFEFF';
    const csvContent = BOM + csvHeader + csvData;
    return csvContent;
  };

  const downloadCSV = () => {
    const csvContent = prepareCSVContent();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
    setDialogOpen(false);
    // event.preventDefault();
  };

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDownloadClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmitEvaluation = async(evaluation_instructions) => {
    const formData = new FormData();
    const csvContent = prepareCSVContent();
    formData.append('csvData', csvContent);
    formData.append('userId', user.uid);
    formData.append('evalComments', evaluation_instructions)
  
    try {
      toast.success("File Sent for Evaluation. We will get back to you as soon as your dataset is ready.");
      const response = await fetch(`${API_URL}/api/evaluate-csv`, {
        method: 'POST',
        body: formData
      });
      // const decoder = new TextDecoder();
      // const reader = response.body.getReader();
      // const { value } = await reader.read();
      // const chunk = decoder.decode(value);
      // const items = JSON.parse(JSON.stringify(chunk));
    } catch (error) {
      toast.error('Error sending CSV to backend:', error);
    }
  };
  
  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="left"
        padding={3}
      >
      <Card 
      style={{ width: '100%', marginBottom: '20px' }}  
      sx={{
        borderRadius: 2,
        boxShadow: 3,
        marginBottom: 4
      }}
    > 
      <CardContent>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={6}>
            <TextField
              label="Describe the data you want to generate in detail in English."
              variant="outlined"
              fullWidth
              required
              multiline
              value={prompt}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '30px'
                },
              }}
              onChange={(e) => setPrompt(e.target.value)}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Upload files to provide context to your data generation." placement="bottom">
                    <span>
                      <IconButton
                        onClick={() => fileInputRef.current.click()}
                        edge="end"
                      >
                        <AttachFile />
                      </IconButton>
                    </span>
                  </Tooltip>
                ),
              }}
              InputLabelProps={{
                sx: {
                   top: '5%'
                },
              }}
              helperText={
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span>{fileName ? `File selected: ${fileName}` : ' '}</span>
                  {fileName && (
                    <IconButton
                      onClick={handleRemoveFile}
                      size="small"
                      sx={{ color: 'red', padding: 0, marginLeft: 1 }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              }
              FormHelperTextProps={{ style: { marginTop: 0,  color: fileName ? 'green' : 'inherit' } }}
            />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                accept=".csv, .pdf, .jpg, .png"
              />
            <Tooltip title="Include latest search results to get updated data. Coming soon!" placement="bottom">
              <span>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      name="termsCheckbox"
                      color="primary"
                    />
                  }
                  label="Include web search results"
                  disabled
                />
              </span>
            </Tooltip>
            {/* {fileName && <Box mt={1}>File selected: {fileName}</Box> */}
          </Grid>
          <Grid item xs={12} sm={2} sx = {{marginTop:'15px'}}>
            <FormControl fullWidth required>
              <InputLabel id="task-select-label">Select Task</InputLabel>
              <Select
                labelId="task-select-label"
                value={selectedOption}
                onChange={handleDropdownChange}
                label="Select Task"
                sx={{
                  borderRadius: '24px', // Adjust this value to increase or decrease roundness
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '24px', // Ensure the outline matches the Select's border radius
                  },
                }}
              >
                <MenuItem value="classification">Classification</MenuItem>
                <MenuItem value="qanda">Question Answering</MenuItem>
                <MenuItem value="insttuning">Instruction Tuning</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2} sx = {{marginTop:'15px'}}>
            <FormControl fullWidth required>
              <InputLabel id="language-select-label">Select Language</InputLabel>
              <Select
                labelId="language-select-label"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                label="Select Language"
                sx={{
                  borderRadius: '24px', // Adjust this value to increase or decrease roundness
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '24px', // Ensure the outline matches the Select's border radius
                  },
                }}
              >
                <MenuItem value="en">English</MenuItem>
                {/* <MenuItem value="te">Telugu</MenuItem> */}
                {/* <MenuItem value="de">German</MenuItem>
               
                {/* <MenuItem value="fr">French</MenuItem> */}
                <MenuItem value="hi">Hindi</MenuItem>
                <MenuItem value="te">Telugu</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1} sx = {{marginTop:'15px'}}>
            <TextField
              label="Number"
              variant="outlined"
              value={number}
              required
              onChange={(e) => setNumber(e.target.value)}
              type="number"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '24px',
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1} sx = {{marginTop:'15px'}}>
            <Button variant="contained" onClick={handleSubmit} fullWidth
            sx={{
              borderRadius: '24px', // Adjust this value to increase or decrease roundness
              height: '56px',
              backgroundColor: 'blue',
              color: 'white'
            }}>
              Go
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>

        {loading && (
          <Box 
            mt={4} 
            display="flex" 
            justifyContent="center" 
            alignItems="top" 
            height="100vh" 
          >
            <CircularProgress />
          </Box>
        )}

        {showGrid && !loading && (
          <Card style={{ width: '100%' }}>
            <CardContent>
            {showGrid && !loading && (
              <Box display="flex" justifyContent="flex-end" mb={2}>
                  <Typography variant="body2" mr={2}>
                    {receivedCount} of {number} rows loaded
                  </Typography>
                  <Button disabled={isDisabled} variant="contained" type="button" size="small" 
                      onClick={(event) => handleButtonClick(event)}
                      sx={{
                        mx: 1,
                        borderRadius: '20px',
                        color: 'blue',
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: 'white',
                        }
                      }}
                  >
                        Submit for Evaluation
                  </Button>
                  <Button disabled={isDisabled} variant="contained" type="button" size="small" 
                      onClick={(event) => handleDownloadClick(event)}
                      sx={{
                        borderRadius: '24px',
                        color: 'blue',
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: 'white',
                        }
                      }}
                  >
                        Download CSV
                  </Button>
              </Box>)
            }
              {showGrid && !loading && (<GridComponent rowData={rowData} colDefs={colDefs} />) }
              {/* {showGrid && !loading && selectedOption === 'insttuning' && (<CardGrid rowData = {rowData} />) } */}
            </CardContent>
          </Card>
        )}
       <PopupFormComponent
        open={isPopupOpen}
        onClose={handleClosePopup}
        onSubmit={handleSubmitEvaluation}
      />
      <TermsDialog open={dialogOpen} handleClose={handleDialogClose} handleAgree={downloadCSV} />
      </Box>
      <ToastContainer position="top-center" autoClose={3000} />
    </Container>
  );
};

export default Home;