export const API_URL = "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "http://localhost:5000"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app";